<template>
  <div class="PlaceOrder">
    <van-sticky style="width: 100%">
      <van-nav-bar title="MY ORDER" left-arrow @click-left="onClickLeft">
        <template #left>
          <van-icon name="arrow-left" color="#ffffff" size="16pt" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="content">
      <!-- <van-badge color="#000000" v-for="item in shoppingFood" :key="item.id"> -->
      <template v-for="mealPeriod in mealPeriodOpenTime">
        <div
          style=""
          :key="mealPeriod.mealPeriodId"
          v-if="isShow(mealPeriod.mealPeriodId)"
        >
          <!--          <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 10pt;margin-bottom: 2pt">-->
          <div class="mealPeriod">{{ mealPeriod.mealPeriod }}</div>
          <div
            style="
              width: 100%;
              font-size: 12pt;
              height: 20pt;
              font-family: PingFangSC-Semibold, PingFang SC;
              color: #272727;
              display: flex;
              align-items: center;
              justify-content: left;
            "
          >
            <img
              v-if="mealPeriod.tips.search('Not available') === -1"
              style="height: 12pt; margin-right: 5pt"
              src="@/assets/img/时间.png"
            />
            <img
              v-else
              style="height: 12pt; margin-right: 5pt"
              src="@/assets/img/时间2.png"
            />
            <span
              class="word-break"
              style="
                font-size: 10pt;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #636464;
                text-align: right;
              "
              >{{ mealPeriod.tips }}</span
            >
          </div>
          <!--          </div>-->
          <div v-for="item in shoppingFood" :key="item.id">
            <div
              class="food"
              v-if="item.mealPeriodId == mealPeriod.mealPeriodId"
            >
              <div class="food-name">
                <!-- <span class="word-break"
                  >{{ item.num + " " + getSize(item) + " * "
                  }}{{ item.name }}</span
                > -->
                <span class="word-break"
                  >{{ item.num + " * " }}{{ item.name }}</span
                >
                <span v-if="showPrice" class="price word-break"
                  >${{ item.allPrice.toFixed(2) }}</span
                >
              </div>
              <div class="subFood" style="background-color: #ffffff">
                <template v-for="sideDish in item.sideDishList">
                  <template v-for="option in sideDish.optionList">
                    <div
                      style="
                        font-size: 12pt;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        color: #272727;
                        line-height: 12pt;
                        width: 100%;
                      "
                      v-if="isShowOption(item, option.id)"
                      :key="option.id"
                    >
                      <span class="word-break"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--&nbsp;&nbsp;({{
                          sideDish.title
                        }}) {{ option.name }}</span
                      >
                    </div>
                  </template>
                </template>
                <div
                  v-if="item.remark"
                  class="word-break"
                  style="
                    font-size: 10pt;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    color: #6ec8ee;
                    line-height: 12pt;
                    width: 100%;
                    padding-left: 20px;
                  "
                >
                  (Note) {{ item.remark }}
                </div>
                <div class="divider"></div>
              </div>
              <div class="item border-radius" style="margin-bottom: 0">
                <div
                  class="item-name"
                  style="
                    text-align: right;
                    margin-top: 0pt;
                    font-family: PingFangSC-Semibold, PingFang SC;
                  "
                >
                  <span
                    @click="edit(item)"
                    style="
                      margin-right: 10pt;
                      font-size: 11pt;
                      font-weight: bold;
                    "
                    >Edit<van-icon name="edit"
                  /></span>
                  <span
                    @click="remove(item.id)"
                    style="font-size: 11pt; font-weight: bold"
                    >Remove<van-icon name="cross"
                  /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="promo">
      <div v-if="isHaveDiscount" style="margin-bottom: 10pt">
        <span
          v-if="!showPromo"
          style="
            font-size: 11pt;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: #2b2e36;
            text-decoration: underline;
          "
          @click="() => (showPromo = true)"
          >I have a Promotional Code.</span
        >
      </div>
      <div class="promo-pop" v-if="showPromo">
        <span
          class="word-break"
          style="
            font-size: 11pt;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #636464;
          "
          >Please input your promo code</span
        >
        <div style="display: flex; margin-top: 5pt; align-items: center">
          <span style="font-size: 11pt; font-weight: bold; margin-right: 5pt"
            >Promo Code</span
          >
          <a-input style="flex: 1" v-model="promoCode" />
        </div>
      </div>
    </div>
    <div class="bottom">
      <div style="display: flex; align-items: center">
        <van-badge
          v-if="allNum > 0"
          :content="allNum"
          style="margin-right: 10pt"
        >
          <img src="../assets/img/购物车.png" alt="" @click="placeOrder" />
        </van-badge>
        <img
          v-else
          src="../assets/img/购物车.png"
          style="margin-right: 10pt"
          alt=""
          @click="placeOrder"
        />
        <span v-if="showPrice" class="price">${{ allPrice.toFixed(2) }}</span>
      </div>
      <div class="btn" @click="placeOrder">
        <span>Review Order</span>
      </div>
    </div>
    <van-popup
      class="foodModel"
      v-model="foodInfoShow"
      round
      position="bottom"
      :style="{ height: '75%' }"
    >
      <div style="height: 100%; background-color: #ffffff">
        <div class="title">
          <span class="word-break">{{ selectFood.name }}</span>
          <van-icon @click="close" class="icon" size="15pt" name="cross" />
        </div>
        <div
          style="
            height: calc(100% - 130pt);
            overflow-y: auto;
            background-color: #ffffff;
          "
        >
          <div class="item">
            <div class="item-name">
              <span class="word-break">Quantity</span>
            </div>
            <van-stepper v-model="selectFood.num" min="0" integer />
          </div>
          <!-- <div
            class="item"
            v-if="selectFood.halfPrice != null && selectFood.halfPrice != 0"
          >
            <div class="item-name">
              <span class="word-break"
                >{{ selectFood.halfName }} Or {{ selectFood.fullName }}</span
              >
            </div>
            <van-radio-group class="radio-group" v-model="selectFood.isFull">
              <van-radio :name="false" icon-size="12pt">
                {{ selectFood.halfName }} &nbsp;&nbsp;
                <span>${{ selectFood.halfPrice }}</span>
              </van-radio>
              <van-radio :name="true" icon-size="12pt">
                {{ selectFood.fullName }} &nbsp;&nbsp;
                <span>${{ selectFood.fullPrice }}</span>
              </van-radio>
            </van-radio-group>
          </div> -->
          <div
            class="item"
            v-for="(item, index) in selectFood.foods"
            :key="index"
          >
            <div class="item-name">
              <span class="word-break">{{ item.title }}</span>
            </div>
            <!-- <van-radio-group
              v-if="item.type == 'Radio'"
              class="radio-group"
              v-model="item.checked"
            >
              <van-radio
                v-for="food in item.optionList"
                :key="food.id"
                :name="food.id"
                icon-size="12pt"
                >{{ food.name }}
                &nbsp;&nbsp;
                <span v-if="food.price && food.price != 0.0"
                  >+&nbsp;${{ food.price }}</span
                ></van-radio
              >
            </van-radio-group> -->
            <van-checkbox-group
              class="checked-group"
              v-model="item.checked"
              @change="checkedChange(item)"
            >
              <van-checkbox
                v-for="food in item.optionList"
                :key="food.id"
                shape="square"
                icon-size="12pt"
                :name="food.id"
                @click="checkbox(item, food.id)"
                >{{ food.name }} &nbsp;&nbsp;
                <span v-if="food.price && food.price != 0 && showPrice"
                  >+&nbsp;${{ food.price }}</span
                ></van-checkbox
              >
            </van-checkbox-group>
          </div>
          <div
            class="item"
            v-if="selectFood.drinks && selectFood.drinks.length > 0"
          >
            <div class="item-name">
              <span>Beverage</span>
            </div>
            <div class="select">
              <div
                v-for="(item, index) in selectFood.drinks"
                :key="index"
                :class="item.checked ? 'type-checked' : 'type'"
                @click="choseDrink(item.id)"
              >
                <span class="word-break">{{ item.title }}</span>
              </div>
            </div>
            <van-radio-group
              class="radio-group"
              v-model="selectFood.drinksChildrenChecked"
            >
              <van-radio
                v-for="item in selectFood.drinksChildren"
                :key="item.id"
                :name="item.id"
                icon-size="12pt"
              >
                {{ item.name }}&nbsp;&nbsp;<span
                  v-if="item.price && item.price != 0 && showPrice"
                  >+&nbsp;${{ item.price }}</span
                >
              </van-radio>
            </van-radio-group>
          </div>
          <div
            class="remark"
            v-if="posConfig.posBridge == 'HVT' || selectFood.messageModifierId"
          >
            <div class="item-name" style="font-size: 12pt">
              <span class="word-break">Special Instructions</span>
              <br />
              <span
                class="word-break"
                style="font-size: 10pt; font-weight: normal"
                >(Please indicate any allergies or dietary restrictions)</span
              >
            </div>
            <van-field
              class="input word-break"
              maxlength="60"
              v-model="selectFood.remark"
              rows="1"
              autosize
              type="textarea"
            />
          </div>
        </div>
        <div style="padding: 10pt">
          <van-button class="btn" size="large" @click="editShoppingFood"
            >SAVE</van-button
          >
        </div>
      </div>
    </van-popup>

    <van-popup v-model="disclaimerShow" round>
      <div class="modal">
        <div class="title">
          <span>DISCLAIMER</span>
        </div>
        <div class="info" style="max-height: 220pt; overflow-y: scroll">
          <div v-for="(item, index) in disclaimer" :key="item.id">
            <p class="word-break">
              {{ disclaimer.length > 1 ? index + 1 + ". " : ""
              }}{{ item.disclaimer }}
            </p>
          </div>
        </div>
        <div class="btn-box">
          <van-button
            class="btn decline-btn"
            size="large"
            @click="disclaimerShow = false"
            >DECLINE
          </van-button>
          <van-button class="btn" size="large" @click="gotoConfirmOrder"
            >ACCEPT
          </van-button>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="discountShow" round>
      <div class="modal">
        <div class="title">
          <span>Promo Code</span>
        </div>
        <div class="info" style="max-height: 220pt; overflow-y: scroll">
          <p class="word-break">
            The Promp Code is invalid. <br />
            Please click 'Go Back' to edit the code or 'Ok' to continue
            submitting order.
          </p>
        </div>
        <div class="btn-box">
          <van-button
            class="btn decline-btn"
            size="large"
            @click="discountShow = false"
            >Go Back
          </van-button>
          <van-button class="btn" size="large" @click="gotoConfirmOrder"
            >OK
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Toast } from "vant";
import lodash from "lodash";
import { getAction, deleteAction, putAction, postAction } from "@/api/manage";
import { randomUUID } from "@/utils/utils";
import moment from "moment-timezone";

export default {
  name: "PlaceOrder",
  components: {},
  data() {
    return {
      url: {
        createNewTickets: "/api/common/hotel/data/sub_order/getFoodDeliverTime",
        createNoAdvanceNewTickets:
          "/api/common/hotel/data/sub_order/getFoodOrderNum",
        getDiscountByCode: "/adminApi/foodOrder/getDiscountByCode",
        getAllDiscountList: "/adminApi/foodOrder/getAllDiscountList",
      },
      foodInfoShow: false,
      selectFood: {}, //弹窗的餐品信息
      mealPeriodAdvanced: [],
      hotelId: "",
      roomNum: "",
      locationType: "Guest Room",
      posConfig: JSON.parse(localStorage.getItem("posConfig")) || {
        posBridge: "HVT",
      },
      disclaimerShow: false,
      disclaimer: [],
      promoCode: "", //折扣码
      showPromo: false, //是否输入折扣码
      discountShow: false, //折扣码失效弹窗
      isHaveDiscount: false, //该酒店是否支持折扣
      showPrice: true,
    };
  },
  computed: {
    ...mapGetters({
      shoppingFood: "foods", //购物车中的餐品
      allPrice: "allPrice",
      allNum: "allNum",
      mealPeriodOpenTime: "mealPeriodOpenTime",
      discount: "discount",
    }),
  },
  watch: {
    // 深度 watcher
    shoppingFood: {
      handler: function (val, oldVal) {
        if (val.length == 0) {
          history.back();
        }
      },
      deep: true,
    },
  },
  created() {
    // console.log(this.shoppingFood);
    window.sessionStorage.removeItem("paymentStorageDara");
    if (this.$route.query.room) {
      this.roomNum = this.$route.query.room;
    }
    if (this.$route.query.location) {
      this.locationType = this.$route.query.location;
    }
    if (this.shoppingFood.length == 0) {
      let query = {
        hotelname: localStorage.getItem("hotelName"),
      };
      if (this.roomNum) {
        query.room = this.roomNum;
      }
      if (this.locationType) {
        query.location = this.locationType;
      }
      this.$router.push({
        path: "/",
        query: query,
      });
    }
    this.hotelId = localStorage.getItem("hotelId");
    this.mealPeriodAdvanced = JSON.parse(
      localStorage.getItem("mealPeriodAdvanced")
    );

    this.getDisclaimer();
    // console.log(this.shoppingFood)
    if (this.discount) {
      this.showPromo = true;
      this.promoCode = this.discount.code;
    }
    if (
      this.mealPeriodOpenTime.length > 0 &&
      this.mealPeriodOpenTime[0].discount
    ) {
      this.isHaveDiscount = true;
    }

    if (
      localStorage.getItem("hotelName") == "pmont" ||
      localStorage.getItem("hotelName") == "PMONT"
    ) {
      this.showPrice = false;
    }
  },
  mounted() {},
  methods: {
    moment,
    ...mapActions([
      "removeFood",
      "editFood",
      "addOrder",
      "addDiscount",
      "removeDiscount",
    ]),
    getDisclaimer() {
      // disclarmer去重
      this.disclaimer = [];
      let shoppingFood = this.shoppingFood;
      let disclaimerIds = [];
      for (let i = 0; i < shoppingFood.length; i++) {
        for (let j = 0; j < shoppingFood[i].disclaimer.length; j++) {
          let curDisclaimer = shoppingFood[i].disclaimer[j];
          if (
            disclaimerIds.findIndex((item) => item === curDisclaimer.id) === -1
          ) {
            disclaimerIds.push(curDisclaimer.id);
            this.disclaimer.push(curDisclaimer);
          }
        }
      }
      // disclaimer 排序
      this.disclaimer.sort((a, b) => {
        return Number(a.sort) - Number(b.sort);
      });
      disclaimerIds = null;
    },
    isShow(id) {
      let flag = false;
      for (let i = 0; i < this.shoppingFood.length; i++) {
        const element = this.shoppingFood[i];
        if (element.mealPeriodId == id) {
          flag = true;
          break;
        }
      }
      return flag;
    },
    getSize(item) {
      if (item.isFull) {
        if (item.fullName) {
          return "(" + item.fullName + ")";
        } else {
          return "";
        }
      } else {
        if (item.halfName) {
          return "(" + item.halfName + ")";
        } else {
          return "";
        }
      }
    },
    onClickLeft() {
      let query = {
        hotelname: localStorage.getItem("hotelName"),
      };
      if (this.locationType) {
        query.location = this.locationType;
      }
      if (this.$route.query.room) {
        query.room = this.$route.query.room;
      }
      this.$router.push({
        path: "/",
        query: query,
      });
    },
    choseDrink(selectFood, val) {
      var target = null;
      for (let i = 0; i < selectFood.drinks.length; i++) {
        const item = selectFood.drinks[i];
        item.checked = false;
        if (item.id == val) {
          target = item;
        }
      }
      if (target) {
        target.checked = true;
        selectFood.drinksChildren = target.optionList;
        if (selectFood.drinksChildren.length > 0) {
          selectFood.drinksChildrenChecked = selectFood.drinksChildren[0].id;
        }
      }
      this.changeFood(selectFood);
    },
    placeOrder() {
      if (this.promoCode) {
        getAction(
          `${this.url.getDiscountByCode}?hotelId=${this.hotelId}&code=${this.promoCode}`
        ).then((res) => {
          if (res.data.length != 0) {
            let discount = res.data[0];
            let flag = false; //折扣码是否有效
            if (!discount.expire) {
              flag = discount.enable;
            } else {
              let expire = moment
                .tz(
                  moment(discount.expire + " 23:59:59").format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  localStorage.getItem("timeZone")
                )
                .valueOf();
              let now = moment(new Date()).tz(localStorage.getItem("timeZone"));
              if (discount.enable && now.valueOf() <= expire) {
                flag = true;
              }
            }
            if (flag) {
              this.addDiscount(discount);
              if (this.disclaimer.length != 0) {
                this.disclaimerShow = true;
              } else {
                this.disclaimerShow = false;
                this.gotoConfirmOrder();
              }
            } else {
              this.removeDiscount();
              this.discountShow = true;
            }
          } else {
            this.removeDiscount();
            this.discountShow = true;
          }
        });
      } else {
        this.removeDiscount();
        if (this.disclaimer.length != 0) {
          this.disclaimerShow = true;
        } else {
          this.disclaimerShow = false;
          this.gotoConfirmOrder();
        }
      }
    },
    gotoConfirmOrder() {
      let query = {};
      if (this.roomNum) {
        query.room = this.roomNum;
      }
      if (this.locationType) {
        query.location = this.locationType;
      }
      if (!this.roomNum && !this.locationType) {
        this.$router.push({ path: "/confirmOrder" });
      } else {
        this.$router.push({
          path: "/confirmOrder",
          query: query,
        });
      }
    },
    changeFood(food) {
      //计算价格
      food.allPrice = 0;
      for (let i = 0; i < food.drinksChildren.length; i++) {
        const drink = food.drinksChildren[i];
        if (drink.id == food.drinksChildrenChecked) {
          food.allPrice += Number(drink.price);
        }
      }
      for (let i = 0; i < food.foods.length; i++) {
        const element = food.foods[i];
        for (let j = 0; j < element.optionList.length; j++) {
          const item = element.optionList[j];
          if (element.checked.indexOf(item.id) != -1) {
            food.allPrice += Number(item.price);
          }
          // if (element.type == "Radio") {
          //   if (item.id == element.checked) {
          //     food.allPrice += Number(item.price);
          //   }
          // } else {
          //   if (element.checked.indexOf(item.id) != -1) {
          //     food.allPrice += Number(item.price);
          //   }
          // }
        }
      }
      food.allPrice += Number(food.fullPrice);
      food.allPrice = food.allPrice * food.num;
    },
    remove(id) {
      this.removeFood(id);
    },
    edit(item) {
      this.selectFood = lodash.cloneDeep(item);
      this.foodInfoShow = true;
    },
    close() {
      this.foodInfoShow = false;
    },
    editShoppingFood() {
      if (this.selectFood.num > 0) {
        this.selectFood.allPrice = 0;
        //计算价格
        for (let i = 0; i < this.selectFood.drinksChildren.length; i++) {
          const drink = this.selectFood.drinksChildren[i];
          if (drink.id == this.selectFood.drinksChildrenChecked) {
            this.selectFood.allPrice += Number(drink.price);
          }
        }
        for (let i = 0; i < this.selectFood.foods.length; i++) {
          const element = this.selectFood.foods[i];
          for (let j = 0; j < element.optionList.length; j++) {
            const item = element.optionList[j];
            if (element.checked.indexOf(item.id) != -1) {
              this.selectFood.allPrice += Number(item.price);
            }
            // if (element.type == "Radio") {
            //   if (item.id == element.checked) {
            //     this.selectFood.allPrice += Number(item.price);
            //   }
            // } else {
            //   if (element.checked.indexOf(item.id) != -1) {
            //     this.selectFood.allPrice += Number(item.price);
            //   }
            // }
          }
        }
        this.selectFood.allPrice += Number(this.selectFood.fullPrice);
        this.selectFood.allPrice =
          this.selectFood.allPrice * this.selectFood.num;
        this.editFood(this.selectFood);
        this.foodInfoShow = false;
      } else {
        this.removeFood(this.selectFood.id);
        this.foodInfoShow = false;
      }
    },
    checkbox(item, id) {
      if (item.type == "Radio") {
        if (item.checked.length == 0) {
          Toast("Choose at least 1");
          item.checked.push(id);
        } else if (item.maxNum == 1) {
          item.checked = [id];
        }
      }
    },
    checkedChange(item) {
      if (item.type == "Radio" && item.maxNum == 1) {
        return;
      }
      if (item.checked.length > item.maxNum) {
        Toast("Choose up to " + item.maxNum);
        item.checked.pop();
        return;
      }
    },
    isShowOption(food, id) {
      for (let i = 0; i < food.foods.length; i++) {
        const element = food.foods[i];
        if (element.checked.indexOf(id) != -1) {
          return true;
        }
        // if (element.type == "Radio") {
        //   if (element.checked == id) {
        //     return true;
        //   }
        // } else {
        //   if (element.checked.indexOf(id) != -1) {
        //     return true;
        //   }
        // }
      }

      if (id == food.drinksChildrenChecked) {
        return true;
      }
      return false;
    },
    getTimeColumns() {
      // console.log(this.shoppingFood)
      this.orderData = [];
      let newMealArr = [];
      this.mealPeriodOpenTime.forEach((item) => {
        if (
          this.shoppingFood.findIndex(
            (food) => item.mealPeriodId == food.mealPeriodId
          ) != -1
        ) {
          newMealArr.push(item);
        }
      });

      // console.log(newMealArr)
      for (let i = 0; i < newMealArr.length; i++) {
        const item = newMealArr[i];
        // console.log(this.shoppingFood.findIndex(food => food.mealPeriodId == item.mealPeriodId) == -1)
        // if(this.shoppingFood.findIndex(food => food.mealPeriodId == item.mealPeriodId) == -1){
        //   continue
        // }

        // 如果不支持提前点餐
        // console.log(!item.isAllowAdvanced);
        if (!item.isAllowAdvanced) {
          // console.log("不支持提前点餐");

          let obj = {
            id: randomUUID(),
            mealPeriod: item.mealPeriod,
            mealPeriodId: item.mealPeriodId,
            timeColumns: [],
            time: "",
            timePicker: false,
            shoppingFood: [],
            feeList: [],
            subTotal: 0,
            total: 0,
            nowOrderNum: 0,
          };
          //构造该类别的餐品
          for (let j = 0; j < this.shoppingFood.length; j++) {
            const food = this.shoppingFood[j];
            if (item.mealPeriodId == food.mealPeriodId) {
              obj.shoppingFood.push(food);
            }
          }

          getAction(
            `${this.url.createNoAdvanceNewTickets}?hotelId=${this.hotelId}&mealPeriod=${obj.mealPeriod}`
          ).then((res) => {
            // console.log(res)
            obj.nowOrderNum = res.data;
            // console.log(obj)
          });
          continue;
        }
        // 间隔时间 默认15分钟
        let intervalTime = 15;
        if (item.tips.search("Not available") != -1) {
          intervalTime = Number(item.advancedConfig.closeStatus);
        } else {
          intervalTime = Number(item.advancedConfig.openStatus);
        }
        let obj = {
          id: randomUUID(),
          mealPeriod: item.mealPeriod,
          mealPeriodId: item.mealPeriodId,
          timeColumns: [],
          time: "",
          timePicker: false,
          shoppingFood: [],
          feeList: [],
          subTotal: 0,
          total: 0,
          capacity: item.advancedConfig.capacity,
        };
        //构造该类别的餐品
        for (let j = 0; j < this.shoppingFood.length; j++) {
          const food = this.shoppingFood[j];
          if (item.mealPeriodId == food.mealPeriodId) {
            obj.shoppingFood.push(food);
          }
        }
        //构造送餐时间
        let now = moment(new Date()).tz(localStorage.getItem("timeZone"));
        let asap = moment(now.valueOf() + 1000 * 60 * intervalTime).tz(
          localStorage.getItem("timeZone")
        );
        let date = now.format("YYYY/MM/DD");
        let tomorrow = moment(now.valueOf() + 1000 * 60 * 60 * 24)
          .tz(localStorage.getItem("timeZone"))
          .format("YYYY/MM/DD");

        for (let i = 0; i < item.mealPeriodOpenTimeArr.length; i++) {
          const condition = item.mealPeriodOpenTimeArr[i];
          let startTime = moment.tz(
            moment(condition.date + " " + condition.fromTime).format(
              "YYYY-MM-DD HH:mm"
            ),
            localStorage.getItem("timeZone")
          );
          let endTime = moment.tz(
            moment(condition.date + " " + condition.toTime).format(
              "YYYY-MM-DD HH:mm"
            ),
            localStorage.getItem("timeZone")
          );

          //今天
          if (date == condition.date) {
            //只有该时间段还未过去
            if (now.valueOf() < endTime.valueOf()) {
              if (obj.timeColumns.length == 0) {
                let deliver = {
                  text: date,
                  children: [],
                };
                obj.timeColumns.push(deliver);
              }
              let beginTime = ""; //起始时间
              //确定是否从asap开始
              if (startTime.valueOf() < asap.valueOf()) {
                var minute = moment(asap).format("mm");
                if (Number(minute) >= 30) {
                  let chazhi = 60 - Number(minute);
                  beginTime = asap.valueOf() + 1000 * 60 * chazhi;
                } else {
                  let chazhi = 30 - Number(minute);
                  beginTime = asap.valueOf() + 1000 * 60 * chazhi;
                }
                obj.timeColumns[0].children.push({
                  text: "ASAP (30 Mins)",
                });
              } else {
                beginTime = startTime.valueOf() + 1000 * 60 * intervalTime;
              }
              while (beginTime <= endTime.valueOf()) {
                obj.timeColumns[0].children.push({
                  text: moment(beginTime)
                    .tz(localStorage.getItem("timeZone"))
                    .format("h:mm A"),
                });
                beginTime += 1000 * 60 * intervalTime;
              }
            }
          }
          //明天
          else if (tomorrow == condition.date) {
            if (obj.timeColumns.length < 2) {
              let deliver = {
                text: tomorrow,
                children: [],
              };
              obj.timeColumns.push(deliver);
            }

            let beginTime = startTime.valueOf() + 1000 * 60 * intervalTime;

            while (beginTime <= endTime.valueOf()) {
              obj.timeColumns[obj.timeColumns.length - 1].children.push({
                text: moment(beginTime)
                  .tz(localStorage.getItem("timeZone"))
                  .format("h:mm A"),
              });
              beginTime += 1000 * 60 * intervalTime;
            }
            //   console.log(this.timeColumns[this.timeColumns.length - 1].children)
          }
          //其他日期
          else {
            if (obj.timeColumns.length < 2) {
              let deliver = {
                text: moment(condition.date).format("MM/DD/YYYY"),
                children: [],
              };
              obj.timeColumns.push(deliver);
            }

            let beginTime = startTime.valueOf() + 1000 * 60 * intervalTime;
            while (beginTime <= endTime.valueOf()) {
              obj.timeColumns[obj.timeColumns.length - 1].children.push({
                text: moment(beginTime)
                  .tz(localStorage.getItem("timeZone"))
                  .format("h:mm A"),
              });
              beginTime += 1000 * 60 * intervalTime;
            }
          }
        }
        if (obj.timeColumns.length > 0) {
          // console.log(this.timeColumns)
          if (obj.timeColumns[0].text == "Today") {
            if (obj.timeColumns[0].children[0].text == "ASAP (30 Mins)") {
              obj.time = obj.timeColumns[0].children[0].text;
            } else {
              obj.time =
                obj.timeColumns[0].text +
                " " +
                obj.timeColumns[0].children[0].text;
            }
          } else {
            obj.time =
              obj.timeColumns[0].text +
              " " +
              obj.timeColumns[0].children[0].text;
          }
        }
        if (obj.shoppingFood.length > 0) {
          // console.log(obj)

          postAction(
            `${this.url.createNewTickets}?hotelId=${this.hotelId}&mealPeriod=${obj.mealPeriod}&capacity=${item.advancedConfig.capacity}`,
            obj.timeColumns
          ).then((res) => {
            if (res.success) {
              for (let i = 0; i < res.data.length; i++) {
                res.data[i].text =
                  res.data[i].text == date
                    ? "Today"
                    : res.data[i].text == tomorrow
                    ? "Tomorrow"
                    : res.data[i].text;
              }
              obj.timeColumns = res.data;
              this.orderData.push(obj);
            }
          });
        }
      }
      // console.log(this.orderData)
    },
  },
};
</script>

<style lang="scss" scoped>
.PlaceOrder {
  .word-break {
    word-break: normal;
    font-size: 10pt;
  }

  /deep/ .ant-input {
    border: 1pt solid #979797;
    border-radius: 0;
    box-shadow: none !important;
  }

  /deep/ .ant-input:hover {
    border: 1pt solid #979797;
    box-shadow: none;
  }

  height: 100%;
  background: #f5f4f4;
  display: flex;
  flex-flow: column;
  align-items: center;

  /deep/ .van-nav-bar {
    background-color: $primary-color;
    height: 35pt;
    width: 100%;

    .van-nav-bar__title {
      color: #fff;
      font-size: 14pt;
    }
  }

  /deep/ .van-grid-item__content {
    background: #f5f4f4;
    padding: 17pt 3.75pt 7.5pt;
  }

  /deep/ .van-nav-bar__content {
    height: 100%;
  }

  .content {
    width: calc(100% - 12pt);
    height: calc(100% - 140pt);
    padding: 0 10pt;
    text-align: left;
    overflow: auto;

    /deep/ .van-badge__wrapper {
      width: 100%;
      margin-top: 10pt;
    }

    .mealPeriod {
      font-size: 10pt;
      //font-family: PingFangSC-Semibold, PingFang SC;
      font-family: ArialRoundedMTBold;
      font-weight: 600;
      color: #2b2e36;
      background-color: $primary-color;
      color: #fff;
      padding: 4pt;
      margin-top: 10pt;
      border-radius: 3pt;
      width: fit-content;
      white-space: nowrap;
    }

    .food {
      width: 100%;
      text-align: left;
      background: #f9f9f9;
      border-radius: 7pt;
      margin-top: 4pt;
      margin-bottom: 8pt;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .food-name {
        background-color: #ffffff;
        padding: 4pt 10pt 0pt;
        //border-bottom: 1pt #dcdcdc solid;
        border-radius: 7pt 7pt 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        span {
          font-size: 11pt;
          font-family: PingFangSC-Semibold, PingFang SC;
          color: #2b2e36;
        }

        .price {
          font-size: 12pt;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #636464;
          margin-left: 10pt;
        }
      }

      .item {
        background-color: #ffffff;
        padding: 0pt 10pt 5pt;
        margin-bottom: 2pt;

        .item-name {
          display: flex;
          justify-content: right;
          align-items: center;
          height: 24pt;
          span {
            font-size: 12pt;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: #272727;
          }
        }

        .radio-group {
          /deep/ .van-radio__label {
            font-size: 12pt;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2b2e36;
            line-height: 20pt;
            margin-left: 10pt;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-right: 30pt;
          }

          /deep/ .van-radio__icon--checked .van-icon {
            //background-color: #686868;
            //border-color: #686868;
            background-color: $primary-color;
            border-color: $primary-color;
          }
        }

        .select {
          display: flex;
          align-items: center;
          margin-bottom: 5pt;

          .type {
            width: 50pt;
            height: 20pt;
            background: #ffffff;
            border-radius: 3.5pt;
            border: 1pt solid #979797;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10pt;

            span {
              font-size: 12pt;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2b2e36;
            }
          }

          .type-checked {
            // background: #f7f7f7;
            //background: rgba(0, 0, 0, 0.7);
            background: $primary-color;
            //border: 1pt solid #333333;
            border: 1pt solid $primary-color;
            width: 50pt;
            height: 20pt;
            border-radius: 3.5pt;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10pt;

            span {
              font-size: 12pt;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              //   color: #2b2e36;
              color: #ffffff;
            }
          }
        }
      }

      .border-radius {
        border-radius: 0 0 7pt 7pt;
      }
    }
  }

  .promo {
    width: calc(100% - 12pt);
    height: 65pt;
    padding: 0 10pt;
    margin-top: 5pt;
    display: flex;
    align-items: flex-end;

    .promo-pop {
      width: 100%;
      height: 100%;
      text-align: left;
      background: #fff;
      border-radius: 7pt;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding: 10pt;
    }
  }

  .bottom {
    height: 30pt;
    width: calc(100% - 20pt);
    background: #ffffff;
    border-radius: 25pt;
    margin: 5pt 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    /deep/ .van-badge--fixed {
      top: 10pt;
    }

    img {
      width: 20pt;
      height: 20pt;
      background-repeat: no-repeat;
      margin-left: 20pt;
    }

    .price {
      font-size: 13pt;
      font-family: Arial-Black, Arial;
      font-weight: 900;
      color: #2e2e2e;
    }

    .btn {
      width: 120pt;
      height: 30pt;
      background-color: $primary-color;
      border-radius: 25pt;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 13pt;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .foodModel {
    text-align: left;
    background: #f9f9f9;

    .title {
      background-color: #ffffff;
      padding: 0 10pt;
      border-bottom: 1pt #dcdcdc solid;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60pt;

      span {
        font-size: 14pt;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2b2e36;
      }
    }

    .item {
      background-color: #ffffff;
      padding: 0pt 10pt 5pt;
      margin-bottom: 2pt;

      .item-name {
        span {
          font-size: 12pt;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #272727;
        }
      }

      .checked-group {
        /deep/ .van-checkbox__label {
          font-size: 12pt;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2b2e36;
          line-height: 20pt;
          margin-left: 10pt;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-right: 30pt;
        }

        /deep/ .van-checkbox__icon--checked .van-icon {
          //background-color: #686868;
          //border-color: #686868;
          background-color: $primary-color;
          border-color: $primary-color;
        }
      }

      .radio-group {
        /deep/ .van-radio__label {
          font-size: 12pt;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2b2e36;
          line-height: 20pt;
          margin-left: 10pt;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-right: 30pt;
        }

        /deep/ .van-radio__icon--checked .van-icon {
          //background-color: #686868;
          //border-color: #686868;
          background-color: $primary-color;
          border-color: $primary-color;
        }
      }

      .select {
        display: flex;
        align-items: center;
        margin-bottom: 5pt;

        .type {
          width: 60pt;
          height: 20pt;
          background: #ffffff;
          border-radius: 3.5pt;
          border: 1pt solid #979797;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10pt;

          span {
            font-size: 12pt;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2b2e36;
          }
        }

        .type-checked {
          // background: #f7f7f7;
          //background: rgba(0, 0, 0, 0.7);
          background: $primary-color;
          //border: 1pt solid #333333;
          border: 1pt solid $primary-color;
          width: 60pt;
          height: 20pt;
          border-radius: 3.5pt;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10pt;

          span {
            font-size: 12pt;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            // color: #2b2e36;
            color: #ffffff;
          }
        }
      }
    }

    .remark {
      background-color: #ffffff;
      padding: 0pt 10pt 5pt;

      .item-name {
        span {
          font-size: 12pt;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #272727;
        }
      }

      .input {
        margin-top: 5pt;
        margin-bottom: 10pt;
        border-radius: 1pt;
        border: 1pt solid #979797;
      }
    }

    .btn {
      height: 30pt;
      border-radius: 5pt;
      background-color: $primary-color;
      border-color: $primary-color;
      color: #fff;

      /deep/ .van-button__text {
        font-size: 12pt;
      }
    }
  }
}

.subFood {
  position: relative;
  //&:after{
  //  position: absolute;
  //  content:'';
  //  width: calc(100% - 20pt);
  //  height: 1pt;
  //  background-color: #dcdcdc;
  //  bottom: 1pt;
  //  left: 10pt;
  //}
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 2pt 0;

  .word-break {
    text-align: left;
    width: 100%;
    width: calc(100% - 20pt);
  }
}

.divider {
  width: calc(100% - 20pt);
  height: 1pt;
  background-color: #dcdcdc;
  bottom: 1pt;
}

.modal {
  width: 250pt;
  // height: 150pt;
  text-align: left;
  padding: 10px;

  .title {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 14pt;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2b2e36;
    }
  }

  .info {
    margin-top: 10pt;
    font-size: 10pt;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: normal;
    color: #535353;
    padding: 0 10pt;
    // color: #979797;
    min-height: 50pt;
  }

  .order {
    margin: 10pt 0 5pt 0;
    padding: 10pt;
    border: 1px #535353 dashed;

    .text {
      font-size: 11pt;
      line-height: 15pt;
    }
  }

  .btn-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8pt;
    margin-top: 1em;
    margin-bottom: 1em;

    .decline-btn {
      background-color: #fff;
      border-color: $primary-color;
      color: $primary-color;
    }
  }

  .btn {
    background-color: $primary-color;
    border-color: $primary-color;
    color: #fff;
    width: 35%;
    height: 30px;
  }
}
</style>
